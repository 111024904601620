import React from 'react';
import {Outlet} from 'react-router-dom';

import {Seo, Hint, Shutdown, Navigation, Stories} from '../../components';
import {SERVER} from '../../constants/config';

import styles from './styles.module.scss';

const ArticleView = ({article, stories, categories, settings}) => {
  const date = new Date(article.updated_at);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const articleUpdateTime = `${day}.${month}.${year}`;
  const articleImage = `${SERVER}${article.image?.url}`;

  return (
    <div className={styles.container}>
      <Navigation cart categories={categories} />
      <Seo
        path={'category'}
        title={article?.seo?.title}
        keywords={article?.seo?.keywords}
        description={article?.seo?.description}
        url={article?.seo?.url}
      />
      <Shutdown data={settings} />
      <Stories data={stories} />
      <Navigation categories={categories} />
      <div className={styles.article}>
        <div className={styles.articleHeader}>
          <div className={styles.articleTitle}>{article.title}</div>
          <div className={styles.articleTime}>{articleUpdateTime}</div>
        </div>
        <div className={styles.articleBody}>
          <div className={styles.articleImaWrapper}>
            <img className={styles.articleImg} src={articleImage} />
          </div>
          <div
            className={styles.articleContent}
            dangerouslySetInnerHTML={{__html: article.content}}
          />
        </div>
      </div>
      <Hint />
      <Outlet />
    </div>
  );
};

export default ArticleView;
