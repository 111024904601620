import i18n from 'i18next';

import {DEFAULT_LANG} from '../constants/data';
import logger from './logger';

export const currentLang = () => {
  const lang = window.location.pathname.slice(1, 3);
  const languages = Object.keys(i18n.options.resources);
  const foundLang = languages.find(value => value === lang);
  return foundLang ? foundLang : DEFAULT_LANG;
};

export const setDefaultLang = async () => {
  try {
    const lang = currentLang();
    await i18n.changeLanguage(lang);
  } catch (err) {
    logger(err);
  }
};
